import { Box, Typography } from "@mui/material";
import { ReactComponent as AboutUsImage } from "../../../assets/images/AboutUs.svg";
import SectionTitle from "./SectionTitle";
import styled from "styled-components";

export const AboutUsContainer = styled(Box)`
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -2px;
  gap: 30px;
`;

export const ContentContainer = styled(Box)`
  max-width: 1000px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AboutUsText = styled(Typography)`
  margin-bottom: 30px;
  font-weight: 600;
  width: 100%;
  max-width: 900px;
  line-height: 1.5;
  text-align: center;

  font-size: 32px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ImageContainer = styled(Box)`
  flex: 1;
  max-width: 500px;
  text-align: center;
  width: 100%;
  .img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const AboutUs = () => {
  return (
    <AboutUsContainer className="about-us">
      <ContentContainer>
        <SectionTitle title="About Us" />
        <AboutUsText
          variant="body1"
          fontWeight={900}
          fontFamily={"Plus Jakarta Sans"}
          fontSize={32}
        >
          We're a passionate team of innovators obsessed with using AI to
          streamline content creation. We believe AI can revolutionise the
          writing industry by making high-quality content generation faster and
          more accessible.
        </AboutUsText>
      </ContentContainer>

      <ImageContainer>
        <AboutUsImage className="img" />
      </ImageContainer>
    </AboutUsContainer>
  );
};

export default AboutUs;
