import { Box } from "@mui/material";
import styled from "styled-components";

export const TextFieldStyle = styled(Box)`
  .field-label {
    color: var(--slate-blue-color);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      padding: 0 14px 0 0 !important;
    }
  }
`;
