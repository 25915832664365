import { Box } from "@mui/material";
import { LandingPageStyled } from "./LandingPage.styles";
import HeroSection from "../../components/landingPage/HeroSection";
import Footer from "../../components/landingPage/Footer/Footer";
import AboutUs from "../../components/landingPage/AboutUs";
import ComparisonSection from "../../components/landingPage/ComparisonSection";
import ChooseUs from "../../components/landingPage/ChooseUs/ChooseUs";

const LandingPage = () => {
  return (
    <LandingPageStyled>
      <HeroSection />
      <AboutUs />
      <ChooseUs />
      <ComparisonSection />
      <Footer />
    </LandingPageStyled>
  );
};

export default LandingPage;
