import styled from "styled-components";
import heroImage from "../../../assets/images/387d0223b2809f69edfd8a6d52733e29.png";
import { Box } from "@mui/material";

export const LandingPageStyled = styled(Box)`
  color: white;
  display: flex;
  flex-direction: column;
  /* background-image: radial-gradient(circle, #36408b, #0e1125),
    linear-gradient(to right, #36408b, #0e1125); */
  background-image: -webkit-linear-gradient(200deg, #36408b 20%, #0e1125 80%);
  background-image: -moz-linear-gradient(200deg, #36408b 20%, #0e1125 80%);
  background-image: -o-linear-gradient(200deg, #36408b 20%, #0e1125 80%);
  background-image: linear-gradient(200deg, #36408b 20%, #0e1125 80%);
  gap: 40px;
  .hero {
    background-image: url(${heroImage});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 100px;
  }
`;
