import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, styled } from "@mui/material";
import useSession from "../../../../helpers/useLocalStore";
import CreatePartner from "../../../components/partials/PartnersPartial/PartnerCreate";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/elements/Layout";

const BoxStyled = styled(Box)({
  display: "inline-block",
  margin: "0px auto",
  backgroundColor: "#151b30",
  padding: "20px",
});

const OnboardingPartner = () => {
  const { logout } = useSession();
  const navigate = useNavigate();

  const handleContinue = () => {
    console.log("Continue");
  };

  const handleCancel = () => {
    navigate("/onboarding");
  };

  return (
    <Layout isRaw={true}>
      <BoxStyled>
        <h1 style={{ textAlign: "center", color: "#fff", marginTop: "0px" }}>
          Welcome to Datahub
        </h1>
        <CreatePartner onBack={handleCancel} />
      </BoxStyled>
    </Layout>
  );
};

export default OnboardingPartner;
