import Layout from "../components/elements/Layout";
import CompaniesPartial from "../components/partials/Companies";

const Companies = () => {
  return (
    <Layout page="Companies">
      <CompaniesPartial />
    </Layout>
  );
};

export default Companies;
