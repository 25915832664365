import { Box } from "@mui/material";
import ComparisonImage from "../../../assets/images/image (1).png";
import SectionTitle from "./SectionTitle";
import styled from "styled-components";

export const Container = styled(Box)`
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -2px;
  gap: 30px;
`;

export const ImageContainer = styled(Box)`
  flex: 1;
  text-align: center;
  .image {
    width: 100%;
    max-width: 1000px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
`;
const ComparisonSection = () => {
  return (
    <Container>
      <SectionTitle title="Comparison" subTitle="Datahub vs Competitors" />
      <ImageContainer>
        <img src={ComparisonImage} alt="Logo" className="image" />
      </ImageContainer>
    </Container>
  );
};

export default ComparisonSection;
