import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSessionStore from "../../../../helpers/useLocalStore";

interface PracticeFormInputs {
  name: string;
}

interface PracticeCreateProps {
  onComplete?: Function;
  onCancel?: Function;
}
const CreatePractice = (props: PracticeCreateProps) => {
  const { sessionStore } = useSessionStore();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<PracticeFormInputs>();
  const [createPractice, { loading, error }] = useMutation<
    ApiClient.GetPractices["practices"][0]
  >(apiClient.mutations.CREATE_PRACTICE);

  const handleCreatePractice = (practice: any) => {
    createPractice({
      variables: {
        practice: {
          ...practice,
          companyId: sessionStore?.currentCompanyId,
        },
      },
    })
      .then(() => {
        if (props.onComplete) props.onComplete();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e?.preventDefault();
    props?.onCancel?.();
  };

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit(handleCreatePractice)}>
        {/* register your input into the hook by invoking the "register" function */}
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {error && error?.message}
            <FormControl>
              <Typography component="h4">New Practice</Typography>
            </FormControl>
            <br />
            <br />
            <FormControl>
              <InputLabel htmlFor="name">Practice Name</InputLabel>
              <Input
                id="name"
                defaultValue=""
                {...register("name")}
                required={true}
              />
            </FormControl>
            {/* include validation with required or other standard HTML validation rules */}
            {/* errors will return when field validation fails  */}
            <br />
            <br />
            <Button type="submit" variant="contained">
              Create
            </Button>
            &nbsp;
            {props.onCancel && (
              <Button variant="contained" type="submit" onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </>
        )}
      </form>
    </Box>
  );
};

export default CreatePractice;
