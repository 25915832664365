import { Box } from "@mui/material";
import styled from "styled-components";

const ChooseUsCardStyled = styled(Box)`
  &.card {
    background-image: radial-gradient(circle, #1f2970, #0e1125),
      linear-gradient(200deg, #36408b, #0e1125);
    border: 1px solid #5a4ae3;
    padding: 20px;
    width: 100%;
    max-width: 384px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 250px;
  }

  span {
    display: block;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #fff;
  }

  p {
    font-size: 14px;
    color: #ddd;
  }
  img{
    width: 20%;
    height: auto;
  }
`;

const ChooseUsCard = ({
  icon,
  title,
  desp,
}: {
  icon: any;
  title: string;
  desp: string;
}) => {
  return (
    <ChooseUsCardStyled className="card">
      <img src={icon} alt="Logo" />
      <h3>{title}</h3>
      <p>{desp}</p>
    </ChooseUsCardStyled>
  );
};

export default ChooseUsCard;
