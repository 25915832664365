import { Typography, Button, Container, Box } from "@mui/material";
import Header from "./Header";
import datahubDisplay from "../../../assets/images/datahub-display.png";
import styled from "styled-components";

const HeroSectionStyled = styled(Box)`
  .hero {
    background: url("/path-to-your-image.jpg") no-repeat center center;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    padding: 16px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(5px);
  }

  @media (min-width: 768px) {
    .hero-container {
      margin-top: 255px;
      padding: 24px;
    }
  }

  .hero-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 600px) {
    .hero-title {
      font-size: 48px;
      line-height: 50px;
    }
  }

  @media (min-width: 960px) {
    .hero-title {
      font-size: 64px;
      line-height: 70px;
    }
  }

  @media (min-width: 1280px) {
    .hero-title {
      font-size: 80px;
      line-height: 90px;
    }
  }

  .hero-subtitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
  }

  @media (min-width: 600px) {
    .hero-subtitle {
      font-size: 36px;
      line-height: 45px;
    }
  }

  @media (min-width: 960px) {
    .hero-subtitle {
      font-size: 50px;
      line-height: 60px;
    }
  }

  @media (min-width: 1280px) {
    .hero-subtitle {
      font-size: 64px;
      line-height: 75px;
    }
  }

  .hero-description {
    margin-bottom: 16px;
    width: 100%;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
  }

  @media (min-width: 600px) {
    .hero-description {
      width: 80%;
      font-size: 16px;
    }
  }

  @media (min-width: 960px) {
    .hero-description {
      width: 600px;
      font-size: 18px;
    }
  }

  .hero-button {
    background: #5a4ae3;
    padding: 8px 20px;
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 14px;
    transition: background 0.3s ease;
  }

  .hero-button:hover {
    background: #4a3ad3;
    color: #ffffff;
  }

  @media (min-width: 600px) {
    .hero-button {
      padding: 10px 30px;
      font-size: 16px;
    }
  }

  .hero-image {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding: 20px;
  }

  .hero-image svg {
    width: 100%;
    height: auto;
    max-width: 800px;
  }

  @media (max-width: 768px) {
    .hero-image svg {
      max-width: 600px;
    }
  }

  @media (max-width: 480px) {
    .hero-image svg {
      max-width: 300px;
    }
  }
`;

const HeroSection = () => {
  return (
    <HeroSectionStyled className="hero">
      <Header />
      <Container maxWidth="lg" className="hero-container">
        <Typography variant="h2" className="hero-title">
          Sync PIMS Data Seamlessly
        </Typography>

        <Typography variant="h4" className="hero-subtitle">
          Cost Effectively
        </Typography>

        <Typography variant="body1" className="hero-description">
          Eliminate PIMS subscription costs while enjoying real-time data sync,
          actionable insights, and enhanced efficiency—all at a fraction of the
          price.
        </Typography>

        <Box>
          <Typography variant="h4">Need an account?</Typography>
          <br />
          <a
            href="mailto:datahub@nextinline.io"
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary" className="hero-button">
              <span>Signup for a Free Demo</span>
            </Button>
          </a>
        </Box>
      </Container>
      <Box className="hero-image">
        <img src={datahubDisplay} style={{ width: 800 }} />
      </Box>
    </HeroSectionStyled>
  );
};

export default HeroSection;
