import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { BoxStyled, ModalComponentStyled } from "./modal-component.styles";
import { Close } from "@mui/icons-material";
import Fade from "@mui/material/Fade";
import { Box } from "@mui/material";
import { ModalComponentProps } from "../modal-component/common-types";

const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  subTitle,
  content,
  open,
  onClose,
  width,
}) => {
  return (
    <ModalComponentStyled>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={open}>
          <BoxStyled width={width}>
            <div className="title-container">
              <Box>
                {title && (
                  <Typography id="modal-modal-title">{title}</Typography>
                )}
                {subTitle && (
                  <Typography id="modal-modal-sub-title">{subTitle}</Typography>
                )}
              </Box>
              <Box sx={{ alignSelf: "baseline" }}>
                <span className="close-icon" onClick={onClose}>
                  <Close />
                </span>
              </Box>
            </div>
            <Box className="content-container-modal">{content}</Box>
          </BoxStyled>
        </Fade>
      </Modal>
    </ModalComponentStyled>
  );
};

export default ModalComponent;
