import React, { useState, useEffect, useContext } from "react";
import { SwitchSiteModalProps } from "./types";
import ModalForm from "../modal-form/modal-form";
import { Box, Button, Divider } from "@mui/material";
import ModalComponent from "../modal-component/modal-component";
import { DataCenterPartialsStyled } from "../../partials/DataCenter/styles";
import DataCenterList from "../../partials/DataCenter/DataCenterList";
import { ListItem } from "../../partials/DataCenter/DataCenterList/types";
import { formatSubtitle } from "../../partials/DataCenter";
import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { DataCenterContext } from "../../partials/DataCenter/context";
import useSessionStore from "../../../../helpers/useLocalStore";

const ModalBody: React.FC<SwitchSiteModalProps> = ({ onClose }) => {
  const {
    selectedCompany,
    setSelectedCompany,
    selectedPractice,
    setSelectedPractice,
    selectedSite,
    setSelectedSite,
  } = useContext(DataCenterContext);

  const { sessionStore, setSessionStore } = useSessionStore();

  const [practicesData, setPracticesData] = useState<ListItem[]>([]);
  const [sitesData, setSitesData] = useState<ListItem[]>([]);

  const { data } = useQuery<ApiClient.GetInitialData>(
    apiClient.queries.GET_CURRENT_USER_DATA
  );

  const handleSubmit = async () => {
    onClose && onClose();
  };

  const companiesData: ListItem[] =
    data?.companies?.map((company) => {
      return {
        label: company.name,
        value: company.id,
      };
    }) || [];

  const handleCompanySelected = (id: number, name: string) => {
    setSelectedCompany({ label: name, value: id });
    setSitesData([]);

    const practices =
      data?.companies
        ?.find((company) => company.id === id)
        ?.practices?.map((practice) => {
          return {
            label: practice.name,
            value: practice.id,
          };
        }) || [];

    setPracticesData(practices);

    if (practices.length) {
      const firstPractice = practices[0];
      handlePracticeSelected(
        selectedPractice?.value || firstPractice.value,
        selectedPractice?.label || firstPractice.label,
        id
      );
    }
  };

  const handlePracticeSelected = (
    id: number,
    name: string,
    companyId?: number
  ) => {
    setSelectedPractice({
      label: name,
      value: id,
    });

    companyId = companyId || selectedCompany?.value;

    const sites =
      data?.companies
        ?.find((company) => company.id === companyId)
        ?.practices?.find((practice) => practice.id === id)
        ?.sites?.map((site) => {
          return {
            label: site.name,
            value: site.id,
          };
        }) || [];

    setSitesData(sites);

    if (sites.length) {
      const firstSite = sites[0];
      handleSiteSelected(
        selectedSite?.value || firstSite.value,
        selectedSite?.label || firstSite.label
      );
    }
  };

  const handleSiteSelected = (id: number, name: string) => {
    setSelectedSite({
      label: name,
      value: id,
    });
    if (id) {
      setSessionStore({
        ...Object(sessionStore),
        currentSiteId: id,
      });
    }
  };

  useEffect(() => {
    if (data && data?.companies?.length) {
      handleCompanySelected(
        selectedCompany?.value || data.companies[0]?.id,
        selectedCompany?.label || data.companies[0]?.name
      );
    }
  }, [data]); // eslint-disable-line

  return (
    <ModalForm>
      <DataCenterPartialsStyled>
        <Divider className="divider" />
        <Box className="content-container">
          <DataCenterList
            title="Companies"
            subTitle={formatSubtitle(companiesData, "Companies")}
            listItems={companiesData}
            onSelect={(id, name) => {
              handleCompanySelected(id, name);
            }}
            selectedListItem={selectedCompany?.value}
          />
          <DataCenterList
            title="Practices"
            subTitle={formatSubtitle(practicesData, "Practices")}
            listItems={practicesData}
            onSelect={(id, name) => {
              handlePracticeSelected(id, name);
            }}
            selectedListItem={selectedPractice?.value}
          />
          <DataCenterList
            title="Sites"
            subTitle={formatSubtitle(sitesData, "Sites")}
            listItems={sitesData}
            onSelect={(id, name) => {
              handleSiteSelected(id, name);
            }}
            selectedListItem={selectedSite?.value}
          />
        </Box>
        <Box className="bottom-section">
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={handleSubmit}
          >
            Apply & Show Data
          </Button>
        </Box>
      </DataCenterPartialsStyled>
    </ModalForm>
  );
};

const SwitchSiteModal: React.FC<SwitchSiteModalProps> = ({ onClose }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    onClose && onClose();
  };

  return (
    <div>
      <ModalComponent
        title="Switch Site"
        subTitle="Select the a company, practice and a site to change the results of the data table."
        content={<ModalBody onClose={handleClose} />}
        open={openModal}
        onClose={handleClose}
      />
    </div>
  );
};

export default SwitchSiteModal;
