import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { PartnerHook } from "../app/components/partials/Webhooks/types";

loadDevMessages();
loadErrorMessages();

export let host = "http://localhost:8082";

if (!window.location.hostname.includes("localhost")) {
  host = "https://datahub-registry-api.vetsuite.io";
}
export let uri = `${host}/graphql`;

if (!window.location.hostname.includes("localhost")) {
  host = "https://datahub-registry-api.vetsuite.io";
}
let dataHubApiUri = `${host}/api/internal/graphql`;

const httpLink = createHttpLink({
  uri,
});

const dataHubApihttpLink = createHttpLink({
  uri: dataHubApiUri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const sessionString = localStorage.getItem("session");
  let session: { token?: string } = {};
  try {
    if (sessionString) {
      session = JSON.parse(sessionString);
    }
  } catch (error: any) {
    localStorage.removeItem("session");
  }
  const token = session?.token;
  let optional = {};
  if (token) {
    optional = {
      authorization: `Bearer ${token ?? ""}`,
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...optional,
    },
  };
});

const authLinkDataHubApi = setContext((_, { headers }) => {
  const sessionString = localStorage.getItem("session");
  if (!sessionString) {
    return { headers };
  }
  const session = JSON.parse(sessionString);
  const token = session?.token;

  let optional: any = {
    "x-l-site-id": session.currentSiteId,
    authorization: `Bearer ${token ?? ""}`,
  };
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...optional,
    },
  };
});
export namespace ApiClient {
  export type GetCalendar = GetAppointments & GetBlockoffs;

  export interface InviteUser {
    id: number;
    firstName: String;
    lastName: String;
    email: String;
    mobilePhone: String;
    token: String;
    userType: String;
    isSuper: Boolean;
  }

  export interface GetAppointments {
    appointments: {
      id: string;
      startsAt: string;
      endsAt: string;
      reason: string;
      client?: {
        id: string;
        firstName: string;
        lastName: string;
        patient: {
          id: string;
          name: string;
        };
      };
      resourceId: string;
      resource?: {
        id: string;
        firstName: string;
        lastName: string;
      };
      appointmentType?: {
        id: string;
        name: string;
      };
      appointmentStatus?: {
        id: string;
        name: string;
      };
    }[];
  }
  export interface AddAgentVersion {
    addAgentVersionToSite: {
      id: number;
      createdAt: string;
      agentVersionId: number;
    };
  }
  export interface RemoveAgentVersion {
    removeAgentVersionToSite: {
      success: boolean;
    };
  }
  export interface GetAgentVersions {
    agentVersions: {
      id: number;
      createdAt: string;
      version: string;
      description: string;
    }[];
  }
  export interface GetBlockoffs {
    blockoffs: {
      id: number;
      startsAt: string;
      endsAt: string;
      reason: string;
      resourceId: string;
      resource?: {
        id: string;
        fullName: string;
      };
    }[];
  }
  export interface GetServers {
    servers: {
      id: number;
      url: string;
      name: string;
    }[];
  }
  export interface GetProvision {
    provisions: {
      ok: boolean;
    }[];
  }
  export interface GetCompanies {
    companies: {
      id: number;
      name: string;
    }[];
  }
  export interface GetPractices {
    practices: {
      id: number;
      name: string;
      remoteId: string;
    }[];
  }
  export interface GetSites {
    sites: {
      id: number;
      createdAt: string;
      name: string;
      phoneNumber: string;
      description: string;
      integration: string;
      practiceId: number;
      companyId: number;
      isDataEnabled: boolean;
      currentAgentVersion: string;
      agentVersionsCount: string;
    }[];
  }
  export interface GetSite {
    site: {
      id: number;
      createdAt: string;
      name: string;
      phoneNumber: string;
      description: string;
      integration: string;
      practiceId: number;
      isDataEnabled: boolean;
      currentAgentVersion: string;
      remoteId: string;
      privateKey: string;
      agentVersionUpgrades: {
        id: number;
        createdAt: string;
        version: string;
        description: string;
        agentVersionId: number;
        completedAt: string;
      }[];
    };
  }
  export interface GetPractice {
    practice: {
      id: number;
      createdAt: string;
      name: string;
      phoneNumber: string;
      description: string;
      integration: string;
      practiceId: number;
      isDataEnabled: boolean;
      remoteId: string;
      privateKey: string;
    };
  }
  export interface GetInitialData {
    companies: {
      id: number;
      name: string;
      practices?: {
        id: number;
        name: string;
        remoteId: string;
        sites?: {
          id: number;
          name: string;
          privateKey: string;
          remoteId: string;
        }[];
      }[];
    }[];
  }
  export interface GetPartners {
    partners: {
      id: string;
      name: string;
      apiKey: string;
      isEnabled: boolean;
      singleClientOwnership: boolean;
      createdAt: string;
      updatedAt: string;
    }[];
  }
  export interface GetDataHubPractices {
    practices: {
      id: string;
      name: string;
    }[];
  }
  export type DataHubPatient = {
    id: string;
    name: string;
    birthdate: string;
    weight: number;
    color:string;
    isDeceased: boolean;
    isActive: boolean;
    updatedAt: string;
    allergies: string;
  };
  export interface GetDataHubPatients {
    patients?: {
      patients: DataHubPatient[];
      total: number;
    };
  }
  export type DataHubAppointmentType = {
    name: string;
    covetrusId: string;
    createdAt: string;
    deletedAt: string;
    id: string;
    integration: string;
    integrationId: string;
    practiceId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsId: string;
  };
  export interface GetDataHubAppointmentTypes {
    appointmentTypesByName?: {
      appointmentTypes: DataHubAppointmentType[];
      total: number;
    };
  }
  export type DataHubAppointmentStatus = {
    name: string;
    covetrusId: string;
    createdAt: string;
    deletedAt: string;
    id: string;
    integration: string;
    integrationId: string;
    practiceId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsId: string;
  };
  export interface GetDataHubAppointmentStatuses {
    appointmentStatusByName?: {
      appointmentStatus: DataHubAppointmentStatus[];
      total: number;
    };
  }
  export type DataHubAppointments = {
    appointmentStatusId: string;
    appointmentStatusIntegrationId: string;
    appointmentTypeId: string;
    appointmentTypeIntegrationId: string;
    clientId: string;
    clientIntegrationId: string;
    covetrusId: string;
    createdAt: string;
    deletedAt?: string;
    description: string;
    endsAt?: string;
    entity: string;
    id: string;
    integration: string;
    integrationId: string;
    isActive: boolean;
    isDeleted: boolean;
    isIntake: boolean;
    patientId: string;
    patientIntegrationId: string;
    pimsAppointmentStatusId: string;
    pimsAppointmentTypeId: string;
    pimsClientId: string;
    pimsCreatedAt?: string;
    pimsDeletedAt?: string;
    pimsId: string;
    pimsPatientId: string;
    pimsResourceId: string;
    pimsUpdatedAt?: string;
    practiceId: string;
    reason: string;
    resourceId: string;
    siteId: string;
    startsAt?: string;
    updatedAt: string;
  };
  export interface GetDataHubAppointments {
    appointments?: {
      appointments: DataHubAppointments[];
      total: number;
    };
  }
  export type DataHubBreed = {
    name: string;
    createdAt: string;
    covetrusId: string;
    entity: string;
    id: string;
    integration: string;
    integrationId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsSpeciesId: string;
    pimsUpdatedAt: string;
    practiceId: string;
    speciesId: string;
  };
  export interface GetDataHubBreeds {
    breedsByName?: {
      breeds: DataHubBreed[];
      total: number;
    };
  }
  export type DataHubSpecie = {
    name: string;
    covetrusId: string;
    createdAt: string;
    deletedAt: string;
    id: string;
    integration: string;
    integrationId: string;
    practiceId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsId: string;
  };
  export interface GetDataHubSpecies {
    speciesByName?: {
      species: DataHubSpecie[];
      total: number;
    };
  }
  export type DataHubSex = {
    name: string;
    covetrusId: string;
    createdAt: string;
    deletedAt: string;
    id: string;
    integration: string;
    integrationId: string;
    speciesId: string;
    practiceId: string;
    speciesIntegrationId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsId: string;
  };
  export interface GetDataHubSexes {
    sexesByName?: {
      sexes: DataHubSex[];
      total: number;
    };
  }
  export type DataHubInvoice = {
    covetrusId: string;
    createdAt: string;
    deletedAt: string;
    id: string;
    integration: string;
    integrationId: string;
    practiceId: string;
    isActive: boolean;
    isDeleted: boolean;
    pimsId: string;
  };
  export interface GetDataHubInvoices {
    invoicesBySearch?: {
      invoices: DataHubInvoice[];
      total: number;
    };
  }
  export type DataHubClient = {
    id: string;
    balance: number;
    address: string;
    zipcode: string;
    country: string;
    lastName: string;
    clientStatus: string;
    isDeleted: boolean;
    isActive: boolean;
    firstName: string;
    spouseName: string;
    integration: string;
    city: string;
    state: string;
    emails: {
      type: string;
      email: string;
      isPrimary: boolean;
    }[];
    phoneNumbers: {
      type: string;
      phoneNumber: string;
      isPrimary: boolean;
      isSmsEnabled: boolean;
    }[];
  };
  export interface GetDataHubClients {
    clients?: {
      clients: DataHubClient[];
      total: number;
    };
  }

  export interface GetPartnerHooks {
    partnerHooks: PartnerHook[];
  }
}

const apiClient = {
  connection: new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "dataHubApi",
      authLinkDataHubApi.concat(dataHubApihttpLink),
      authLink.concat(httpLink)
    ),
    cache: new InMemoryCache(),
  }),
  cache: new InMemoryCache({
    addTypename: false, // Not needed here
  }),
  authenticate: (token: string) => {
    if (token) {
      return apiClient.connection.mutate({
        mutation: gql`
          mutation ($token: String!) {
            authenticate(token: $token) {
              firstName
              lastName
              email
              token
              userType
              isSuper
              partner {
                id
                name
                description
                isSandbox
                isEnabled
                singleClientOwnership
                apiKey
                managerId
                updatedAt
                createdAt
                companySandboxId
                apiKeys {
                  id
                  name
                  apiKey
                  isActive
                  isSandbox
                  createdAt
                  updatedAt
                }
              }
            }
          }
        `,
        variables: {
          token,
        },
      });
    }
    return Promise.reject();
  },
  mutations: {
    CREATE_COMPANY: gql`
      mutation ($company: CompanyInput!) {
        createCompany(company: $company) {
          id
          name
        }
      }
    `,
    UPDATE_USER: gql`
      mutation ($user: UserUpdateInput!) {
        updateCurrentUser(user: $user) {
          id
          firstName
          lastName
          email
          mobilePhone
          userType
        }
      }
    `,
    CREATE_PRACTICE: gql`
      mutation ($practice: PracticeInput!) {
        createPractice(practice: $practice) {
          id
          name
        }
      }
    `,
    INVITE_USER: gql`
      mutation ($email: String!) {
        inviteUser(email: $email) {
          id
          firstName
          lastName
          email
          mobilePhone
          token
          userType
          isSuper
        }
      }
    `,
    CREATE_PARTNER: gql`
      mutation ($partner: PartnerInput!) {
        createPartner(partner: $partner) {
          id
          name
          description
          isSandbox
          isEnabled
          singleClientOwnership
          apiKey
          managerId
          updatedAt
          createdAt
          companySandboxId
          apiKeys {
            id
            name
            apiKey
            isActive
            isSandbox
            createdAt
            updatedAt
          }
        }
      }
    `,
    CREATE_PARTNER_HOOK: gql`
      mutation ($partnerHook: CreatePartnerHookInput!) {
        createPartnerHook(partnerHook: $partnerHook) {
          createdAt
          id
          isActive
          name
          parser
          partnerId
          practiceId
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendClientEmail
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendOwnership
          sendPatient
          sendPatientReminder
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone
          updatedAt
          url
        }
      }
    `,

    UPDATE_PARTNER_HOOK: gql`
      mutation ($partnerHook: UpdatePartnerHookInput!) {
        updatePartnerHook(partnerHook: $partnerHook) {
          createdAt
          id
          isActive
          name
          parser
          partnerId
          practiceId
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendClientEmail
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendOwnership
          sendPatient
          sendPatientReminder
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone
          updatedAt
          url
        }
      }
    `,

    DELETE_PARTNER_HOOK: gql`
      mutation ($id: Int!) {
        removePartnerHook(id: $id) {
          success
        }
      }
    `,

    GENERATE_FAKE_DATA: gql`
      mutation ($siteId: Int!) {
        generateFakeData(siteId: $siteId) {
          message
        }
      }
    `,

    CREATE_SITE: gql`
      mutation ($site: SiteInput!) {
        createSite(site: $site) {
          id
          name
        }
      }
    `,
    UPDATE_SITE: gql`
      mutation ($site: UpdateSiteInput!) {
        updateSite(site: $site) {
          id
          name
          description
          integration
          phoneNumber
        }
      }
    `,
    ADD_AGENT_VERSION: gql`
      mutation ($agentVersionId: Int!, $siteId: Int!) {
        addAgentVersionToSite(
          agentVersionId: $agentVersionId
          siteId: $siteId
        ) {
          id
          createdAt
          agentVersionId
        }
      }
    `,
    REMOVE_AGENT_VERSION: gql`
      mutation ($agentVersionId: Int!, $siteId: Int!) {
        removeAgentVersionToSite(
          agentVersionId: $agentVersionId
          siteId: $siteId
        ) {
          success
        }
      }
    `,
    CREATE_AGENT_VERSION: gql`
      mutation ($agentVersion: AgentVersionInput!) {
        createAgentVersion(agentVersion: $agentVersion) {
          id
          createdAt
          version
          description
        }
      }
    `,
    PROVISION_PRACTICE: gql`
      mutation ($provision: ProvisionPracticeInput) {
        provisionPractice(provision: $provision) {
          ok
        }
      }
    `,
    PROVISION_SITE: gql`
      mutation ($provision: ProvisionSiteInput) {
        provisionSite(provision: $provision) {
          ok
        }
      }
    `,
  },
  queries: {
    GET_COMPANIES: gql`
      query {
        companies {
          id
          name
        }
      }
    `,
    GET_PRACTICES: gql`
      query ($companyId: Int) {
        practices(companyId: $companyId) {
          id
          name
          companyId
          remoteId
        }
      }
    `,
    GET_PRACTICE: gql`
      query ($id: Int) {
        practice(id: $id) {
          id
          createdAt
          description
          name
          integration
          companyId
          phoneNumber
          isDataEnabled
          remoteId
          privateKey
        }
      }
    `,
    GET_SITES: gql`
      query {
        sites {
          id
          createdAt
          description
          name
          integration
          practiceId
          companyId
          phoneNumber
          currentAgentVersion
          isDataEnabled
          agentVersionUpgrades {
            version
          }
        }
      }
    `,
    GET_SITE: gql`
      query ($id: Int) {
        site(id: $id) {
          id
          createdAt
          description
          name
          integration
          practiceId
          phoneNumber
          currentAgentVersion
          isDataEnabled
          remoteId
          privateKey
          agentVersionUpgrades {
            id
            createdAt
            version
            description
            agentVersionId
            completedAt
          }
        }
      }
    `,
    GET_APPOINTMENTS: gql`
      query ($siteId: String!, $startingAt: String!, $endingAt: String!) {
        appointments(
          siteId: $siteId
          startingAt: $startingAt
          endingAt: $endingAt
        ) {
          id
          startsAt
          endsAt
          reason
          resourceId
          client {
            id
            firstName
            lastName
            patients {
              id
              name
            }
          }
          resource {
            id
            fullName
          }
          appointmentType {
            id
            name
          }
          appointmentStatus {
            id
            name
          }
        }
      }
    `,
    GET_CALENDAR: gql`
      query (
        $siteId: String!
        $startingAt: String!
        $endingAt: String!
        $resourceId: String
      ) {
        appointments(
          siteId: $siteId
          startingAt: $startingAt
          endingAt: $endingAt
          resourceId: $resourceId
        ) {
          id
          startsAt
          endsAt
          reason
          resourceId
          client {
            id
            firstName
            lastName
            patients {
              id
              name
            }
          }
          appointmentStatus {
            id
            name
          }
          appointmentType {
            id
            name
          }
        }
        blockoffs(
          siteId: $siteId
          startingAt: $startingAt
          endingAt: $endingAt
          resourceId: $resourceId
        ) {
          id
          startsAt
          endsAt
          resourceId
          resource {
            id
            fullName
          }
        }
      }
    `,
    GET_CURRENT_USER_DATA: gql`
      query {
        companies {
          id
          name
          practices {
            id
            name
            sites {
              id
              name
              privateKey
            }
          }
        }
      }
    `,
    GET_AGENT_VERSIONS: gql`
      query {
        agentVersions {
          id
          createdAt
          version
          description
        }
      }
    `,
    GET_SERVERS: gql`
      query {
        servers {
          id
          createdAt
          name
          url
        }
      }
    `,
    GET_PARTNERS: gql`
      query GetPartners {
        partners {
          id
          name
          apiKey
          isEnabled
          singleClientOwnership
          createdAt
          updatedAt
        }
      }
    `,
    GET_DATAHUB_PRACTICES: gql`
      query GetPractices {
        practices {
          id
          name
        }
      }
    `,
    GET_DATAHUB_PATIENTS: gql`
      query GetPatientsBySearch(
        $search: PatientSearchInput
        $pagination: PaginationInput
      ) {
        patients(search: $search, pagination: $pagination) {
          patients {
            id
            name
            birthdate
            weight
            color
            practiceId
            isActive
            isDeceased
            createdAt
            updatedAt
          }
          total
        }
      }
    `,
    GET_DATAHUB_CLIENTS: gql`
      query GetClients(
        $search: ClientSearchInput
        $pagination: PaginationInput
      ) {
        clients(search: $search, pagination: $pagination) {
          clients {
            id
            createdAt
            updatedAt
            deletedAt
            balance
            entity
            unit
            discount
            until
            siteId
            address
            zipcode
            country
            lastName
            clientStatus
            reference
            isDeleted
            addressExtended
            pimsCreatedAt
            isActive
            firstName
            pimsDeletedAt
            codes
            spouseName
            integration
            pimsUpdatedAt
            city
            state
            emails {
              type
              email
              isPrimary
            }
            phoneNumbers {
              type
              phoneNumber
              isPrimary
              isSmsEnabled
            }
          }
          total
        }
      }
    `,
    GET_DATAHUB_SEXES: gql`
      query GetSexesByName($name: String, $pagination: PaginationInput) {
        sexesByName(name: $name, pagination: $pagination) {
          sexes {
            name
            covetrusId
            createdAt
            deletedAt
            id
            integration
            integrationId
            speciesId
            practiceId
            speciesIntegrationId
            isActive
            isDeleted
            pimsId
          }
          total
        }
      }
    `,
    GET_DATAHUB_BREEDS: gql`
      query GetBreedsByName($name: String, $pagination: PaginationInput) {
        breedsByName(name: $name, pagination: $pagination) {
          breeds {
            name
            createdAt
            covetrusId
            entity
            id
            integration
            integrationId
            isActive
            isDeleted
            pimsSpeciesId
            pimsUpdatedAt
            practiceId
            speciesId
          }
          total
        }
      }
    `,
    GET_DATAHUB_SPECIES: gql`
      query GetSpeciesByName($name: String, $pagination: PaginationInput) {
        speciesByName(name: $name, pagination: $pagination) {
          species {
            name
            covetrusId
            createdAt
            deletedAt
            id
            integration
            integrationId
            practiceId
            isActive
            isDeleted
            pimsId
          }
          total
        }
      }
    `,
    GET_DATAHUB_APPOINTMENTS: gql`
      query GetAppointments($pagination: PaginationInput) {
        appointments(pagination: $pagination) {
          appointments {
            appointmentStatusId
            appointmentStatusIntegrationId
            appointmentTypeId
            appointmentTypeIntegrationId
            clientId
            clientIntegrationId
            covetrusId
            createdAt
            deletedAt
            description
            endsAt
            entity
            id
            integration
            integrationId
            isActive
            isDeleted
            isIntake
            patientId
            patientIntegrationId
            pimsAppointmentStatusId
            pimsAppointmentTypeId
            pimsClientId
            pimsCreatedAt
            pimsDeletedAt
            pimsId
            pimsPatientId
            pimsResourceId
            pimsUpdatedAt
            practiceId
            reason
            resourceId
            siteId
            startsAt
            updatedAt
          }
          total
        }
      }
    `,
    GET_DATAHUB_APPOINTMENT_TYPES: gql`
      query GetAppointmentTypesByName(
        $name: String
        $pagination: PaginationInput
      ) {
        appointmentTypesByName(name: $name, pagination: $pagination) {
          appointmentTypes {
            name
            covetrusId
            createdAt
            deletedAt
            id
            integration
            integrationId
            practiceId
            isActive
            isDeleted
            pimsId
            isActive
            defaultDuration
            color
          }
          total
        }
      }
    `,
    GET_DATAHUB_APPOINTMENT_STATUSES: gql`
      query GetAppointmentStatusesByName(
        $name: String
        $pagination: PaginationInput
      ) {
        appointmentStatusByName(name: $name, pagination: $pagination) {
          appointmentStatus {
            name
            covetrusId
            createdAt
            deletedAt
            id
            integration
            integrationId
            practiceId
            isActive
            isDeleted
            pimsId
          }
          total
        }
      }
    `,
    GET_DATAHUB_INVOICES: gql`
      query GetInvoices($name: String, $pagination: PaginationInput) {
        invoicesBySearch(name: $name, pagination: $pagination) {
          invoices {
            covetrusId
            createdAt
            deletedAt
            id
            integration
            integrationId
            practiceId
            isActive
            isDeleted
            pimsId
            isPaid
            amountPaid
            date
          }
          total
        }
      }
    `,

    GET_PARTNERHOOKS: gql`
      query PartnerHooks($practiceId: Int!) {
        partnerHooks(practiceId: $practiceId) {
          createdAt
          id
          isActive
          name
          partnerId
          practiceId
          sendAppointment
          sendAppointmentStatus
          sendAppointmentType
          sendBlockoff
          sendBreed
          sendClient
          sendClientPhoneNumber
          sendClientEmail
          sendCurrency
          sendEmployee
          sendInvoice
          sendLanguage
          sendLocale
          sendOwnership
          sendPatient
          sendPatientReminder
          sendPractice
          sendResource
          sendSex
          sendSpecies
          sendTimezone
          updatedAt
          url
          headers {
            name
            value
          }
        }
      }
    `,
  },
};

export default apiClient;
