import styled from "styled-components";

export const PartnerHookFormStyled = styled.div`
  width: 468px;
  padding-left: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: 96vh;
    overflow-y: auto;
  }
  .fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
    padding: 24px 0px;
    overflow-y: auto;
    flex: 1;
  }

  .actions {
    display: flex;
    gap: 16px;
  }
`;
