import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Column } from "../../elements/TableComponent/types";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { PartnerHook } from "./types";

export const mockData = [
  { id: 1, name: "Company A", webhookUrl: "https://webhook.com/a" },
  { id: 2, name: "Company B", webhookUrl: "https://test2webhook.com/b" },
  { id: 3, name: "Company C", webhookUrl: "https://test4webhook.com/c" },
];

export const WebhooksColumn = (
  handleEditClick: (data: any) => void,
  handleDeleteClick: (data: any) => void,
  handleAddClick: () => void
) =>
  [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "url",
      label: "Url",
    },
    {
      id: "isActive",
      label: "Status",
      render: (_, row: any) => (row.isActive ? "Active" : "In Active"),
    },
    {
      id: "actions",
      label: (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleAddClick}
        >
          <AddIcon />
        </Button>
      ),
      align: "right",
      render: (_, rowData: PartnerHook) => {
        return (
          <Box
            sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Tooltip arrow title="Edit" placement="top">
              <IconButton size="small" onClick={() => handleEditClick(rowData)}>
                <EditIcon htmlColor="rgb(229, 30, 90)" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Delete" placement="top">
              <IconButton
                size="small"
                onClick={() => handleDeleteClick(rowData)}
              >
                <DeleteIcon htmlColor="rgb(229, 30, 90)" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ] as Column[];
