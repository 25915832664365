import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import {
  Link,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CreatePartner from "./PartnerCreate";
import ResponsiveDrawer from "../../elements/Drawer";
import useSessionStore from "../../../../helpers/useLocalStore";

const PartnersPartial = () => {
  const [state, setState] = useState({
    drawerOpen: false,
  });

  const { sessionStore, setSessionStore } = useSessionStore();

  const navigate = useNavigate();
  const { loading, error, data } = useQuery<ApiClient.GetPartners>(
    apiClient.queries.GET_PARTNERS,
    {
      variables: {
        companyId: sessionStore?.currentCompanyId,
      },
    }
  );

  const setDrawer = (value: boolean) => {
    setState({
      ...state,
      drawerOpen: value,
    });
  };

  const handleSelectPartner = (
    partner: ApiClient.GetPartners["partners"][0]
  ) => {
    setSessionStore({
      ...Object(sessionStore),
      currentPartnerId: partner.id,
    });
    navigate("/");
  };

  return (
    <>
      {loading ? (
        <>LOADING...</>
      ) : error ? (
        <>{error.message}</>
      ) : (
        <>
          {sessionStore?.isSuper && sessionStore?.currentCompanyId != null && (
            <div>
              <ResponsiveDrawer
                anchor="right"
                open={state.drawerOpen}
                onClose={setDrawer.bind(null, false)}
              >
                <CreatePartner
                  onComplete={setDrawer.bind(null, false)}
                  onBack={setDrawer.bind(null, false)}
                />
              </ResponsiveDrawer>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">
                    {sessionStore?.isSuper &&
                      sessionStore?.currentCompanyId != null && (
                        <Button
                          variant="contained"
                          onClick={setDrawer.bind(null, true)}
                          size="small"
                        >
                          <AddIcon />
                        </Button>
                      )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.partners.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      <Link href={`/partners/${row.id}`}>{row.name}</Link>
                    </TableCell>
                    <TableCell>
                      <Button onClick={handleSelectPartner.bind(null, row)}>
                        Use
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default PartnersPartial;
