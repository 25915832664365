import { Box } from "@mui/material";
import SectionTitle from "../SectionTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import SyncAPIImage from "../../../../assets/images/image (2).png";
import SyncAPIImage1 from "../../../../assets/images/image (3).png";
import SyncAPIImage2 from "../../../../assets/images/image (4).png";

import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import ChooseUsCard from "./ChooseUsCard";

export const ChooseUsStyled = styled(Box)`
  .mySwiper {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: center;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ChooseUs = () => {
  return (
    <ChooseUsStyled>
      <SectionTitle title="Features" subTitle="why to choose us" />
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          480: {
            slidesPerView: 1.2,
            spaceBetween: 15,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <ChooseUsCard
            icon={SyncAPIImage}
            title="Sync API in seconds"
            desp="Data sync to API in seconds - Near-live changes, with data sent to servers in Seconds - Fast Write backs"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ChooseUsCard
            icon={SyncAPIImage1}
            title="Lower Costs"
            desp="- No hidden fees for specialized records or writebacks - Reduce vendor lock-in and increase visibility"
          />
        </SwiperSlide>
        <SwiperSlide>
          <ChooseUsCard
            icon={SyncAPIImage2}
            title="Structured data"
            desp="Data delivered  structured, type safe webhooks even for the initial sync  Handles large number of items "
          />
        </SwiperSlide>
        <SwiperSlide>
          <ChooseUsCard
            icon={SyncAPIImage2}
            title="Diagnose & resolve"
            desp="Diagnose and resolve errors with ease - Pinpoint issues for faster response"
          />
        </SwiperSlide>
      </Swiper>
    </ChooseUsStyled>
  );
};

export default ChooseUs;
