import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../../../logo.png";
import styled from "styled-components";

const StyledHeader = styled.div`
  .toolbar {
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 150px;
  }

  .nav-links {
    display: flex;
    align-items: center;
    column-gap: 30px;
    flex-grow: 1;
    justify-content: center;
  }

  .button {
    text-transform: capitalize;
    &:hover {
      text-decoration: underline;
    }
  }

  .login-button {
    border: 1px solid white;
    color: white;
    text-transform: capitalize;
    padding: 5px 30px;
    border-radius: 10px;
  }

  .drawer {
    width: 250px;
    padding: 2px;
    height: 100%;
    background: linear-gradient(200deg, #36408b 20%, #0e1125 80%);
    color: white;
  }

  .drawer-button {
    width: 100%;
    text-transform: capitalize;
    color: white;
    text-align: left;
    justify-content: flex-start;
  }
`;

let publicKey = "2f70283a1154479ef3ffba5329fc3b34425b8c4e99f87437cd";
if (window.location.hostname.includes("localhost")) {
  publicKey = "ce71f4f5eee04c273a8e180549e27162301891d580267c654e";
}

const Header = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledHeader>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar className="toolbar">
          <Box>
            <img src={logo} alt="logo" className="logo" />
          </Box>

          {!isMobile && (
            <Box className="nav-links">
              <Button
                color="inherit"
                className="button"
                href="/docs"
                target="_blank"
              >
                Documentation
              </Button>
              <Button
                color="inherit"
                className="button"
                href="https://www.nextinline.io/"
                target="_blank"
              >
                Contact us
              </Button>
            </Box>
          )}

          {!isMobile && (
            <Box>
              <Button
                color="primary"
                variant="outlined"
                className="login-button"
                href={`https://authentication.vetsuite.io?publicKey=${publicKey}`}
                target="_blank"
              >
                Login
              </Button>
            </Box>
          )}

          {isMobile && (
            <IconButton color="inherit" onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}

          <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <Box className="drawer">
              <List>
                <ListItem onClick={() => setOpen(false)}>
                  <Button
                    className="drawer-button"
                    href="/docs"
                    target="_blank"
                  >
                    Documentation
                  </Button>
                </ListItem>
                <ListItem onClick={() => setOpen(false)}>
                  <Button
                    className="drawer-button"
                    href="https://www.nextinline.io"
                    target="_blank"
                  >
                    Contact us
                  </Button>
                </ListItem>
                <ListItem onClick={() => setOpen(false)}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="login-button"
                    href={`https://authentication.vetsuite.io?publicKey=${publicKey}`}
                    target="_blank"
                  >
                    Login
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>
    </StyledHeader>
  );
};

export default Header;
