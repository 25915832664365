import { createTheme } from "@mui/material";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e51e5a", // Ensures the correct primary color for buttons
      contrastText: "#ffffff",
    },
    background: {
      default: "#0a1128",
      paper: "#080f25",
    },
    text: {
      primary: "#ffffff",
      secondary: "#c5c9e0",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: 2,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& input": {
              height: "auto",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          "&:hover": {
            backgroundColor: "#101830",
          },
          "&:active": {
            backgroundColor: "#1a2b4a",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.3) !important",
            color: "rgba(255, 255, 255, 0.7) !important",
          },
        },
        contained: {
          color: "white",
          backgroundColor: "#e51e5a !important", // Ensures override
          "&:hover": {
            backgroundColor: "#d81b52 !important",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(255, 255, 255, 0.3) !important",
            color: "rgba(255, 255, 255, 0.9) !important",
            border: "1px solid rgba(255, 255, 255, 0.4) !important",
          },
        },
        text: {
          color: "white",
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.7) !important",
          },
        },
        outlined: {
          color: "white",
          borderColor: "rgba(255, 255, 255, 0.5)",
          "&.Mui-disabled": {
            borderColor: "rgba(255, 255, 255, 0.4) !important",
            color: "rgba(255, 255, 255, 0.7) !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
            color: "#c5c9e0",
          },
        },
        underlineAlways: {
          textDecoration: "underline",
        },
      },
    },
  },
});

export default defaultTheme;
