import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: #030014;
  padding: 40px 50px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  align-items: flex-start;

  @media (max-width: 1024px) {
    grid-template-columns: 1.5fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    padding: 20px;
    grid-template-columns: 1.5fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const FooterColumn = styled.div`
  flex: 1;
  margin-bottom: 20px;
  text-align: left;
  .footer-desp {
    max-width: 400px;
    color: #9e9fa3b0;
  }
`;

export const FooterTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const FooterLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff;
  transition: color 0.3s ease, text-shadow 0.3s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const SocialProfiles = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const SocialIcon = styled.a`
  color: #666;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
