export const transformData = (data: any, resource: string) => {

   const transformedData= data?.map((item: any) => {
      const commonTransformations = {
        ...item,
        isActive: item.isActive ? "Yes" : "No",
        // actions: generateActions(item.id),
      };

      switch (resource) {
        case "patients":
          return {
            ...commonTransformations,
            isDeceased: item.isDeceased ? "Yes" : "No",
            updatedAt: new Date(item.updatedAt).toDateString(),
          };
        case "appointmentsTypes":
         return commonTransformations
        case "appointmentsStatuses":
        case "breeds":
        case "species":
        case "sexes":
        case "invoices":
          return {
            ...commonTransformations,
            isPaid: item.isPaid ? "Yes" : "No",
            isDeleted: item.isDeleted ? "Yes" : "No",
            amountPaid: `$${item.amountPaid}`,
          };
        case "appointments":
          return {
            ...commonTransformations,
            isDeleted: item.isDeleted ? "Yes" : "No",
          };
        case "clients":
          return commonTransformations;
        default:
          return item;
      }
    }) || []
    return transformedData
  
};
