import LandingPage from "./app/pages/LandingPage/LandingPage";

const Unauthenticated = (props: { status: string; errors: Error | null }) => {
  return (
    <div>
      {props.status === "authenticating" ? (
        <h2>Loading</h2>
      ) : props.status === "unauthorized" ? (
        <h2>Unauthorized</h2>
      ) : (
        // <Authenticate />
        <LandingPage />
      )}
    </div>
  );
};

export default Unauthenticated;
