import { useTheme } from "@emotion/react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import useSession from "../../../../helpers/useLocalStore";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/elements/Layout";
import { useState } from "react";
import PracticeForm from "./PracticeForm";

const HomePractice = () => {
  const [state, setState] = useState<{
    name: string | null;
    phoneNumber: string | null;
    email: string | null;
  } | null>({
    name: null,
    phoneNumber: null,
    email: null,
  });

  const { logout } = useSession();
  const navigate = useNavigate();

  const handleContinue = () => {
    console.log("Continue");
  };

  const handleCancel = () => {
    navigate("/onboarding");
  };

  const handlePracticeFormComplete = () => {};

  return (
    <Layout isRaw={true}>
      <Paper style={{ display: "inline-block", padding: "20px" }}>
        <h1 style={{ textAlign: "center", color: "#fff", marginTop: "0px" }}>
          Welcome to Datahub
        </h1>
        <PracticeForm onComplete={handlePracticeFormComplete} />
        <br />
        <br />
        <h3 style={{ textAlign: "center", color: "#fff" }}>
          Please download the installer.
        </h3>
        <FontAwesomeIcon
          icon={faDownload}
          style={{ color: "white", cursor: "pointer" }}
          size="4x"
        />
        <br />
        <Button
          variant="contained"
          sx={{ marginTop: "20px" }}
          onClick={handleCancel}
        >
          Back
        </Button>
        &nbsp;
        <Button
          variant="contained"
          sx={{ marginTop: "20px" }}
          onClick={handleContinue}
          disabled={true}
        >
          Continue
        </Button>
      </Paper>
    </Layout>
  );
};

export default HomePractice;
