import {
  FooterContainer,
  FooterColumn,
  FooterTitle,
  FooterLink,
} from "./Footer.styles";
import { Typography } from "@mui/material";
import logo from "../../../../logo.png";
import { LinkedIn, Facebook, Instagram } from "@mui/icons-material";

let publicKey = "2f70283a1154479ef3ffba5329fc3b34425b8c4e99f87437cd";
if (window.location.hostname.includes("localhost")) {
  publicKey = "ce71f4f5eee04c273a8e180549e27162301891d580267c654e";
}

const Footer = () => {
  return (
    <FooterContainer>
      <FooterColumn>
        <FooterTitle>
          <img src={logo} alt="logo" style={{ width: 150 }} />
        </FooterTitle>
        <Typography className="footer-desp">
          Eliminate PIMS subscription costs while enjoying real-time data sync,
        </Typography>
      </FooterColumn>
      <FooterColumn>
        <FooterTitle>Options</FooterTitle>
        <FooterLink href="/docs" target="_blank">
          Documentation
        </FooterLink>
        <FooterLink href="https://www.nextinline.io/" target="_blank">
          Contact us
        </FooterLink>
        <FooterLink
          href={`https://authentication.vetsuite.io?publicKey=${publicKey}`}
          target="_blank"
        >
          Login
        </FooterLink>
      </FooterColumn>
      <FooterColumn>
        <FooterTitle>Social Profiles</FooterTitle>
        <FooterLink href="https://www.linkedin.com" target="_blank">
          <LinkedIn sx={{ color: "#0A66C2" }} /> LinkedIn
        </FooterLink>
        <FooterLink href="https://www.facebook.com" target="_blank">
          <Facebook sx={{ color: "#1877F2" }} /> Facebook
        </FooterLink>
        <FooterLink href="https://www.instagram.com" target="_blank">
          <Instagram sx={{ color: "#E4405F" }} /> Instagram
        </FooterLink>
      </FooterColumn>
    </FooterContainer>
  );
};

export default Footer;
