import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSessionStore from "../../../../helpers/useLocalStore";
import { useNavigate } from "react-router-dom";

interface PartnerFormInputs {
  name: string;
  description: string;
  website: string;
  phoneNumber: string;
}

interface PartnerCreateProps {
  onComplete?: Function;
  onBack?: Function;
}
const CreatePartner = (props: PartnerCreateProps) => {
  const { sessionStore, setSessionStore } = useSessionStore();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<PartnerFormInputs>();
  const [createPartner, { loading, error }] = useMutation<{
    createPartner: ApiClient.GetPartners["partners"][0];
  }>(apiClient.mutations.CREATE_PARTNER);

  const navigate = useNavigate();

  const handleCreatePartner = (partner: any) => {
    const partnerData = {
      ...partner,
    };

    createPartner({
      variables: {
        partner: partnerData,
      },
    })
      .then(({ data }) => {
        // if (props.onComplete) props.onComplete();
        navigate("/");
        setSessionStore({
          ...Object(sessionStore),
          partner: data?.createPartner,
          userType: "PARTNER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e?.preventDefault();
    props?.onBack?.();
  };

  return (
    <Box sx={{ p: 2 }} style={{ border: "0px" }}>
      <form onSubmit={handleSubmit(handleCreatePartner)}>
        {/* register your input into the hook by invoking the "register" function */}
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {" "}
            {/* small (sm), medium (md), large (lg), etc. */}
            {error && error?.message}
            <FormControl>
              <Typography component="h4">
                Please enter your company name and description
              </Typography>
            </FormControl>
            <br />
            <br />
            <TextField
              id="name"
              defaultValue=""
              {...register("name")}
              required={true}
              label="Company Name"
            />
            <br />
            <br />
            <TextField
              id="description"
              defaultValue=""
              {...register("description")}
              required={true}
              label="Description"
            />
            <br />
            <br />
            <TextField
              id="website"
              defaultValue=""
              {...register("website")}
              required={true}
              label="Website"
            />
            <br />
            <br />
            <TextField
              id="phoneNumber"
              defaultValue=""
              {...register("phoneNumber")}
              required={true}
              label="Phone Number"
            />
            {/* include validation with required or other standard HTML validation rules */}
            {/* errors will return when field validation fails  */}
            <br />
            <br />
            {props.onBack && (
              <Button variant="contained" type="submit" onClick={handleBack}>
                Back
              </Button>
            )}
            &nbsp;
            <Button type="submit" variant="contained">
              Create
            </Button>
          </>
        )}
      </form>
    </Box>
  );
};

export default CreatePartner;
