import useLocalStorage from "../lib/useLocalStorage";

export interface UserSession {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
  token: string;
  isSuper: Boolean;
  currentCompanyId?: number;
  currentPracticeId?: number;
  currentSiteId?: number;
  isReady: boolean;
  userType?: string;
  partner: {
    id: number;
    name: string;
    description: string;
    isSandbox: boolean;
    isEnabled: boolean;
    singleClientOwnership: boolean;
    apiKey: string;
    companySandboxId: number;
    apiKeys: {
      id: number;
      name: string;
      apiKey: string;
      isActive: boolean;
      isSandbox: boolean;
      createdAt: string;
      updatedAt: string;
    }[];
    managerId: number;
    updatedAt: string;
    createdAt: string;
  };
}

const useSessionStore = (): {
  sessionStore: UserSession | undefined;
  setSessionStore: (session: UserSession) => void;
  logout: () => void;
} => {
  let isReady = false;

  const [sessionStore, setSessionStore, { removeItem }] =
    useLocalStorage<UserSession>("session", {});

  const logout = () => {
    setSessionStore(undefined);
    removeItem();
  };
  if (sessionStore) {
    sessionStore.isReady = isReady ?? false;
  }

  return { sessionStore, setSessionStore, logout };
};

export default useSessionStore;
