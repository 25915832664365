import useSessionStore from "../../../helpers/useLocalStore";
import Layout from "../../components/elements/Layout";
import PartnersPartial from "../../components/partials/PartnersPartial";

const Partners = () => {
  const { sessionStore } = useSessionStore();
  if (!sessionStore?.isSuper) return null;
  return (
    <Layout page="Partners">
      <PartnersPartial />
    </Layout>
  );
};

export default Partners;
