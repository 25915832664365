import styled from "styled-components";

export const SwitchFieldStyled = styled.div`
  display: flex;
  align-items: center;
  .field-label {
    color: var(--slate-blue-color);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }
`;
