import { TextField } from "@mui/material";
import { useState } from "react";

const PracticeForm = (props: { onComplete: Function }) => {
  const [state, setState] = useState<{
    name: string | null;
    phoneNumber: string | null;
    email: string | null;
  } | null>({
    name: null,
    phoneNumber: null,
    email: null,
  });

  const setValue = (entityName: string, e: any) => {
    if (!state) return;
    if (!e) return;

    setState({
      ...state,
      [entityName]: e?.target.value,
    });
  };

  return (
    <>
      <TextField
        type="text"
        placeholder="Email"
        value={state?.email || ""}
        onChange={setValue.bind(null, "email")}
      />
      <br />
      <br />
      <TextField
        type="text"
        placeholder="Phone Number"
        value={state?.phoneNumber || ""}
        onChange={setValue.bind(null, "phoneNumber")}
      />
    </>
  );
};

export default PracticeForm;
