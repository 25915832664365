import { useMutation } from "@apollo/client";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSessionStore from "../../helpers/useLocalStore";
import Layout from "../components/elements/Layout";

interface InviteUser {
  email: string;
}

const Invite = () => {
  const { sessionStore, setSessionStore } = useSessionStore();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<InviteUser>();
  const [createPartner, { loading, error }] = useMutation<{
    createPartner: ApiClient.InviteUser;
  }>(apiClient.mutations.INVITE_USER);

  const navigate = useNavigate();

  const handleCreatePartner = (params: any) => {
    createPartner({
      variables: {
        email: params?.email,
      },
    })
      .then(({ data }) => {
        // if (props.onComplete) props.onComplete();
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout page="Invite">
      <h1>Invite</h1>

      <Box sx={{ p: 2 }} style={{ border: "0px" }}>
        <form onSubmit={handleSubmit(handleCreatePartner)}>
          {/* register your input into the hook by invoking the "register" function */}
          {loading ? (
            <>Loading...</>
          ) : (
            <>
              {" "}
              {/* small (sm), medium (md), large (lg), etc. */}
              <br />
              <br />
              <TextField
                id="email"
                defaultValue=""
                {...register("email")}
                required={true}
                label="Email"
              />
              {/* include validation with required or other standard HTML validation rules */}
              {/* errors will return when field validation fails  */}
              <br />
              <br />
              &nbsp;
              <Button type="submit" variant="contained">
                Create
              </Button>
            </>
          )}
        </form>
      </Box>
    </Layout>
  );
};

export default Invite;
