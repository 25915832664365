import { Box } from "@mui/material";
import styled from "styled-components";
import searchIcon from "../../../../assets/icons/search.svg";

export const IndexStyled = styled(Box)`
  display: flex;
  .sidebar-search-field-container {
    margin-top: 24px;
    .sidebar-search-field {
      height: 44px;
      width: 100%;
      background-color: #242f53;
      border-radius: 12px;
      box-shadow: 0px 1px 2px 0px #1018280d;
      border: none;
      background-image: url(${searchIcon});
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: 14px;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      outline: none;
      padding: 10px 14px 10px 40px;
      &::placeholder {
        color: inherit;
      }
    }
  }
  .logo-and-search-container {
    padding: 24px 24px 10px 24px;
    .logo-container {
      text-align: left;
      .logo {
        width: 142px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .menu-bar {
    padding: 0 0 20px 0;
    flex-grow: 1;
  }
  .main-content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    .content-container {
      max-width: 100% !important;
      margin-top: 0;
      padding: 24px 0 0 0 !important;
      min-height: 100%;
      display: flex;
    }
  }
`;
