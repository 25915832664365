import { Box, Button } from "@mui/material";
import TextInputField from "../../../elements/FormFields/TextInputField/field";
import { useState } from "react";
import SwitchField from "../../../elements/FormFields/SwitchField/SwitchField";
import { PartnerHook } from "../types";
import { PartnerHookFormStyled } from "./EditPartnerHookForm.style";
import { DEFAULT_PARTNER_HOOK } from "./EditPartnerHookForm.utils";

interface WebhookEditFormProps {
  webhook?: PartnerHook;
  onComplete: (webhook: PartnerHook) => void;
  onCancel: () => void;
}

export const WebhookEditForm = (props: WebhookEditFormProps) => {
  const { webhook, onComplete, onCancel } = props;

  const [webhookData, setWebhookData] = useState<PartnerHook>(
    webhook || DEFAULT_PARTNER_HOOK
  );

  const handleInputFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setWebhookData({
      ...webhookData,
      [id]: value,
    });
  };

  const handleSwitchFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    setWebhookData({
      ...webhookData,
      [id]: checked,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onComplete(webhookData);
  };

  const isValid = !!webhookData.name && webhookData.url;

  return (
    <PartnerHookFormStyled role="presentation">
      <form onSubmit={handleSubmit} className="content">
        <div className="fields">
          <TextInputField
            label="Webhook Name"
            name="name"
            value={webhookData.name}
            onChange={handleInputFieldChange}
          />
          <TextInputField
            label="Webhook URL"
            name="url"
            value={webhookData.url}
            onChange={handleInputFieldChange}
          />

          <SwitchField
            name={"isActive"}
            value={webhookData.isActive}
            label={"Is Active"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendAppointment"}
            value={webhookData.sendAppointment}
            label={"Send Appointments"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendAppointmentStatus"}
            value={webhookData.sendAppointmentStatus}
            label={"Send Appointment Status"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendAppointmentType"}
            value={webhookData.sendAppointmentType}
            label={"Send Appointment Type"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendBlockoff"}
            value={webhookData.sendBlockoff}
            label={"Send Blockoff"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendBreed"}
            value={webhookData.sendBreed}
            label={"Send Breed"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendClient"}
            value={webhookData.sendClient}
            label={"Send Client"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendClientPhoneNumber"}
            value={webhookData.sendClientPhoneNumber}
            label={"Send Client Phone Number"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendClientEmail"}
            value={webhookData.sendClientEmail}
            label={"Send Client Email"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendCurrency"}
            value={webhookData.sendCurrency}
            label={"Send Currency"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendEmployee"}
            value={webhookData.sendEmployee}
            label={"Send Employee"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendInvoice"}
            value={webhookData.sendInvoice}
            label={"Send Invoice"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendLanguage"}
            value={webhookData.sendLanguage}
            label={"Send Language"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendLocale"}
            value={webhookData.sendLocale}
            label={"Send Locale"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendOwnership"}
            value={webhookData.sendOwnership}
            label={"Send Ownership"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendPatient"}
            value={webhookData.sendPatient}
            label={"Send Patient"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendPatientReminder"}
            value={webhookData.sendPatientReminder}
            label={"Send Patient Reminder"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendPractice"}
            value={webhookData.sendPractice}
            label={"Send Practice"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendResource"}
            value={webhookData.sendResource}
            label={"Send Resource"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendSex"}
            value={webhookData.sendSex}
            label={"Send Sex"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendSpecies"}
            value={webhookData.sendSpecies}
            label={"Send Species"}
            onChange={handleSwitchFieldChange}
          />

          <SwitchField
            name={"sendTimezone"}
            value={webhookData.sendTimezone}
            label={"Send Timezone"}
            onChange={handleSwitchFieldChange}
          />
        </div>

        <Box className="actions">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        </Box>
      </form>
    </PartnerHookFormStyled>
  );
};
