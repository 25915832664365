import React, { useState } from "react";
import "./App.css";
import apiClient from "./lib/apiClient";
import { ApolloProvider } from "@apollo/client";
import { RouterProvider } from "react-router-dom";
import router from "./app/Router";
import { DataCenterContext } from "./app/components/partials/DataCenter/context";
import useSessionStore from "./helpers/useLocalStore";
import "swiper/css";
import Unauthenticated from "./Unauthenticated";

let params = new URLSearchParams(document.location.search);

type LabelValue = {
  label: string;
  value: number;
};

function App() {
  const { sessionStore, setSessionStore } = useSessionStore();
  const [selectedCompany, setSelectedCompany] = useState<
    LabelValue | undefined
  >();
  const [selectedPractice, setSelectedPractice] = useState<
    LabelValue | undefined
  >();
  const [selectedSite, setSelectedSite] = useState<LabelValue | undefined>();
  let status = "";
  let token: string | null = "";
  if (window.location.pathname === "/session/authentication") {
    token = params.get("token");

    if (token) {
      if (!sessionStore?.email) {
        status = "authenticating";
      }
    }
  }
  const [state, setState] = useState<any>({
    status: status,
    error: "",
  });

  let isAuthenticating = false;
  if (state.status === "authenticating" && token && !isAuthenticating) {
    isAuthenticating = true;
    apiClient
      .authenticate(token)
      .then((response) => {
        setSessionStore(response.data.authenticate);
        window.location.href = "/";
      })
      .catch((error) => {
        setState({ status: "unauthorized", error });
        setTimeout(() => {
          window.location.href = "/session/authentication";
        }, 3000);
      });
  }

  return (
    <ApolloProvider client={apiClient.connection}>
      <DataCenterContext.Provider
        value={{
          selectedCompany,
          selectedPractice,
          selectedSite,
          setSelectedCompany,
          setSelectedPractice,
          setSelectedSite,
        }}
      >
        <div className="App">
          {!sessionStore?.email ? (
            <Unauthenticated status={state.status} errors={state.error} />
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      </DataCenterContext.Provider>
    </ApolloProvider>
  );
}

export default App;
