import { useEffect, useState } from "react";
import Layout from "../components/elements/Layout";
import { host } from "../../lib/apiClient";
import { GraphiQL } from "graphiql";
import "graphiql/graphiql.css";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import "graphiql/graphiql.min.css";
import "codemirror/theme/dracula.css";
import "./docs.scss";
import useSessionStore from "../../helpers/useLocalStore";

const GraphiQLInternal = (props: {}) => {
  const { sessionStore } = useSessionStore();

  const fetcher = async (graphQLParams: any) =>
    fetch(`${host}/api/internal/graphql`, {
      // Ensure this is correct
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStore?.token}`,
      },
      body: JSON.stringify(graphQLParams),
    }).then((res) => res.json());

  return (
    <Layout page="docs" isCompact={true}>
      <GraphiQL
        fetcher={fetcher}
        editorTheme="dracula"
        defaultHeaders={JSON.stringify({})}
      />
    </Layout>
  );
};

export default GraphiQLInternal;
