import TableComponent from "../../elements/TableComponent";
import ResponsiveDrawer from "../../elements/Drawer";
import { useState } from "react";
import * as R from "ramda";
import { mockData, WebhooksColumn } from "./webhooks.utils";
import { WebhookEditForm } from "./EditPartnerHook/EditPartnerHookForm";
import { Box } from "@mui/material";
import WebhookDeleteModal from "./webhook-delete-modal/webhook-delete-modal";
import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { PartnerHook } from "./types";
import useSessionStore from "../../../../helpers/useLocalStore";
import DataCenterPartials from "../DataCenter";

const WebhooksPartials = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState<PartnerHook>();
  const [isCreate, setIsCreate] = useState(false);
  const [isPracticeSelected, setIsPracticeSelected] = useState(false);
  const [deleteModalMeta, setDeleteModalMeta] = useState<PartnerHook>();

  const { sessionStore } = useSessionStore();
  const partnerId = sessionStore?.partner.id;
  const practiceId = sessionStore?.currentPracticeId;

  const { data, refetch } = useQuery<ApiClient.GetPartnerHooks>(
    apiClient.queries.GET_PARTNERHOOKS,
    {
      skip: !isPracticeSelected,
      variables: { practiceId: practiceId },
      fetchPolicy: "network-only",
    }
  );

  const [updatePartnerHook] = useMutation<{
    updatePartnerHook: PartnerHook;
  }>(apiClient.mutations.UPDATE_PARTNER_HOOK);

  const [createPartnerHook] = useMutation<{
    createPartnerHook: PartnerHook;
  }>(apiClient.mutations.CREATE_PARTNER_HOOK);

  const [deletePartnerHook] = useMutation<{
    success: boolean;
  }>(apiClient.mutations.DELETE_PARTNER_HOOK);

  const handleEditClick = (webhook: PartnerHook) => {
    setSelectedWebhook(webhook);
    setDrawerOpen(true);
  };

  const handleDeleteClick = (webhook: any) => {
    setDeleteModalMeta(webhook);
  };

  const handleAddClick = () => {
    setSelectedWebhook(undefined);
    setDrawerOpen(true);
    setIsCreate(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsCreate(false);
    setSelectedWebhook(undefined);
  };

  const handleUpdate = async (updatedWebhook: PartnerHook) => {
    try {
      await updatePartnerHook({
        variables: {
          partnerHook: R.omit(
            ["__typename", "createdAt", "updatedAt"],
            updatedWebhook
          ),
        },
      });

      refetch();
      setSelectedWebhook(undefined);
      setDrawerOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreate = async (webhook: PartnerHook) => {
    try {
      await createPartnerHook({
        variables: {
          partnerHook: R.omit(["__typename", "createdAt", "updatedAt"], {
            ...webhook,
            practiceId: practiceId,
            partnerId: partnerId,
          }),
        },
      });
      refetch();
      setIsCreate(false);
      setDrawerOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async () => {
    try {
      await deletePartnerHook({
        variables: {
          id: deleteModalMeta?.id,
        },
      });
      refetch();
      setDeleteModalMeta(undefined);
      setDrawerOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (!isPracticeSelected) {
    return (
      <DataCenterPartials
        showSite={false}
        link={"/webhooks"}
        enableAutoSelect={false}
        onApply={() => setIsPracticeSelected(true)}
      />
    );
  }

  return (
    <>
      <ResponsiveDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        {(selectedWebhook || isCreate) && (
          <WebhookEditForm
            webhook={selectedWebhook}
            onComplete={isCreate ? handleCreate : handleUpdate}
            onCancel={handleDrawerClose}
          />
        )}
      </ResponsiveDrawer>
      <TableComponent
        columns={WebhooksColumn(
          handleEditClick,
          handleDeleteClick,
          handleAddClick
        )}
        data={data?.partnerHooks || []}
      />

      {deleteModalMeta && (
        <WebhookDeleteModal
          name={deleteModalMeta.name}
          onClose={() => setDeleteModalMeta(undefined)}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default WebhooksPartials;
