import { useEffect, useState } from "react";
import Layout from "../components/elements/Layout";
import apiClient, { ApiClient, host } from "../../lib/apiClient";
import { GraphiQL } from "graphiql";
import "graphiql/graphiql.css";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import "graphiql/graphiql.min.css";
import "codemirror/theme/dracula.css";
import "./docs.scss";
import useSessionStore from "../../helpers/useLocalStore";
import { useQuery } from "@apollo/client";
const defaultQuery = `
  # Welcome to the Datahub GraphiQL editor!
  # This is a tool that allows you to query the Datahub API directly.
  # You can use this tool to explore the API, test queries, and more.
  # To get started, try running the query below.

  query{
    hello{
      status
    }
  }

  # mutation ($client: CreateClientInput!) {
  #   createClient(client: $client) {
  #     id
  #     firstName
  #     lastName
  #     emails {
  #       email
  #       type
  #     }
  #     phoneNumbers {
  #       phoneNumber
  #       type
  #     }
  #   }
  # }
`;
const Docs = (props: {}) => {
  const { sessionStore } = useSessionStore();
  const {
    loading: practicesLoading,
    error: practicesError,
    data: practicesData,
  } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES, {
    variables: {
      companyId: sessionStore?.partner?.companySandboxId,
    },
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStore?.token}`,
  };
  const fetcher = async (graphQLParams: any, options: any) =>
    fetch(`${host}/api/graphql`, {
      // Ensure this is correct
      method: "POST",
      headers: {
        ...headers,
        ...Object(options.headers),
      },
      body: JSON.stringify(graphQLParams),
    }).then((res) => res.json());

  const apiKey = sessionStore?.partner?.apiKeys?.find(
    ({ isSandbox }) => isSandbox
  )?.apiKey;
  return (
    <Layout page="docs" isCompact={true}>
      {practicesLoading ? (
        <div></div>
      ) : (
        <GraphiQL
          fetcher={fetcher}
          editorTheme="dracula"
          defaultQuery={defaultQuery}
          defaultHeaders={JSON.stringify(
            {
              "X-Partner-Api-Key": apiKey,
              "X-Practice-Id": practicesData?.practices?.[0]?.remoteId,
            },
            null,
            4
          )}
        />
      )}
    </Layout>
  );
};

export default Docs;
