import { Switch, Typography } from "@mui/material";
import { SwitchFieldStyled } from "./SwitchField.style";

interface SwitchFieldProps {
  value: boolean;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
}

const SwitchField = (props: SwitchFieldProps) => {
  const {
    name,
    label,
    value,
    disabled = false,
    defaultChecked = false,
    onChange,
  } = props;
  return (
    <SwitchFieldStyled>
      {label && <Typography variant="body1">{label}</Typography>}{" "}
      <Switch
        id={name}
        checked={value}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </SwitchFieldStyled>
  );
};

export default SwitchField;
