import { useEffect, useState } from "react";
import Layout from "../components/elements/Layout";
import { host } from "../../lib/apiClient";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import useSessionStore from "../../helpers/useLocalStore";

const Swagger = (props: {}) => {
  const { sessionStore } = useSessionStore();

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStore?.token}`,
  };

  return (
    <Layout page="docs" isCompact={true}>
      <div
        style={{
          textAlign: "left",
          borderTopLeftRadius: "15px",
          padding: "20px",
          backgroundColor: "white",
          minHeight: "calc(100vh - 25px)",
        }}
      >
        <SwaggerUI
          url={`${host}/api/webhooks/openapi.yaml`}
          requestInterceptor={(req: any) => {
            req.headers = headers;
            req.headers["Authorization"] = "Bearer " + sessionStore?.token;
            return req;
          }}
        />
      </div>
    </Layout>
  );
};

export default Swagger;
