import WebhooksPartials from "../components/partials/Webhooks";
import Layout from "../components/elements/Layout";

const Webhooks = () => {
  return (
    <Layout page="Webhooks">
      <WebhooksPartials />
    </Layout>
  );
};

export default Webhooks;
