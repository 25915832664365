import { useEffect, useState } from "react";
import Layout from "../components/elements/Layout";
import { host } from "../../lib/apiClient";
import { GraphiQL } from "graphiql";
import "graphiql/graphiql.css";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import "graphiql/graphiql.min.css";
import "codemirror/theme/dracula.css";
import "./docs.scss";
import useSessionStore from "../../helpers/useLocalStore";
import { Box, Card, Link, Paper, Typography } from "@mui/material";
import graphqllogo from "../../assets/images/graphgl_logo.svg";
import webhookslogo from "../../assets/images/webhooks-logo.svg";

const Docs = (props: {}) => {
  const { sessionStore } = useSessionStore();

  return (
    <Layout page="docs">
      <Card
        style={{
          textAlign: "center",
          padding: "2em",
          display: "inline-block",
          maxWidth: "670px",
        }}
      >
        <Typography variant="h4" component="h4">
          API Read and Writeback
        </Typography>
        <br />
        <Typography style={{ textAlign: "left" }}>
          Make read requests to search data on the system, or create new records
          that are then stored in remote PIMS.
        </Typography>
        <br />
        <Link href="/docs/graphql">
          <img
            src={graphqllogo}
            alt="GraphQL Logo"
            style={{ width: "100px" }}
          />
          <br />
          <Typography variant="h6" component="h6">
            GraphQL
          </Typography>
        </Link>
      </Card>
      <br />
      <br />
      <br />
      <Card
        style={{
          textAlign: "center",
          padding: "2em",
          display: "inline-block",
          maxWidth: "670px",
        }}
      >
        <Typography variant="h4" component="h4">
          Webhook Changes
        </Typography>
        <br />
        <Typography style={{ textAlign: "left" }}>
          Set up webhooks to receive notifications when Create/Update/Delete
          events occur in the system.
        </Typography>
        <br />
        <Link href="/docs/swagger">
          <img
            src={webhookslogo}
            alt="Webhooks Logo"
            style={{ width: "100px" }}
          />
          <br />
          <Typography variant="h6" component="h6">
            Webhooks
          </Typography>
        </Link>
      </Card>
    </Layout>
  );
};

export default Docs;
