import React, { useState, useEffect } from "react";
import ModalComponent from "../../../elements/modal-component/modal-component";
import { Button, Box } from "@mui/material";

const WebhookDeleteModal: React.FC<{
  name: string;
  onClose?: () => void;
  onConfirm?: () => void;
}> = ({ onClose, onConfirm, name }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    onClose && onClose();
  };

  const handleApply = () => {
    onConfirm && onConfirm(); // Trigger delete action
    handleClose(); // Close the modal after confirmation
  };

  return (
    <ModalComponent
      width="500px"
      title="Delete Webhook"
      subTitle={`Are you sure you want to delete ${name} webhook? This action cannot be undone.`}
      content={
        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
          <Button variant="contained" color="error" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      }
      open={openModal}
      onClose={handleClose}
    />
  );
};

export default WebhookDeleteModal;
