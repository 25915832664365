import React from "react";
import { ReactComponent as LeftLightImage } from "../../../assets/icons/right-light.svg";
import { ReactComponent as RightLightImage } from "../../../assets/icons/left-light.svg";
import { Box, Typography } from "@mui/material";

const styles = {
  container: {
    textAlign: "center",
  },
  titleWrapper: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  titleText: {
    fontSize: "16px",
    fontWeight: 600,
  },
  subTitleText: {
    fontSize: "24px",
    fontWeight: 700,
  },
};

const SectionTitle = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle?: string;
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleWrapper}>
        <RightLightImage />
        <span style={styles.titleText}>{title || ""}</span>
        <LeftLightImage />
      </Box>
      {subTitle && <Typography sx={styles.subTitleText}>{subTitle}</Typography>}
    </Box>
  );
};

export default SectionTitle;
