import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataCenterPartialsStyled } from "./styles";
import DataCenterList from "./DataCenterList";
import { ListItem } from "./DataCenterList/types";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { DataCenterContext } from "./context";
import useSessionStore from "../../../../helpers/useLocalStore";

export const formatSubtitle = (data: any[], title: string) => {
  return (
    (data.length > 1000 ? data.length / 1000 + "K" : data.length) + " " + title
  );
};

interface Props {
  link?: string;
  onApply?: () => void;
  enableAutoSelect?: boolean;
  showSite?: boolean;
}
const DataCenterPartials: React.FC<Props> = (props) => {
  const {
    link = "/data-center-listing",
    onApply = () => {},
    enableAutoSelect = true,
    showSite = true,
  } = props;
  const {
    selectedCompany,
    setSelectedCompany,
    selectedPractice,
    setSelectedPractice,
    selectedSite,
    setSelectedSite,
  } = useContext(DataCenterContext);

  const [practicesData, setPracticesData] = useState<ListItem[]>([]);
  const [sitesData, setSitesData] = useState<ListItem[]>([]);
  const { sessionStore, setSessionStore } = useSessionStore();

  const { data } = useQuery<ApiClient.GetInitialData>(
    apiClient.queries.GET_CURRENT_USER_DATA
  );

  const companiesData: ListItem[] =
    data?.companies?.map((company) => {
      return {
        label: company.name,
        value: company.id,
      };
    }) || [];

  const handleCompanySelected = (id: number, name: string) => {
    setSelectedCompany({ label: name, value: id });
    setSitesData([]);

    const practices =
      data?.companies
        ?.find((company) => company.id === id)
        ?.practices?.map((practice) => {
          return {
            label: practice.name,
            value: practice.id,
          };
        }) || [];

    setPracticesData(practices);

    if (practices.length) {
      const firstPractice = practices[0];
      handlePracticeSelected(firstPractice.value, firstPractice.label, id);
    }
  };

  const handlePracticeSelected = (
    id: number,
    name: string,
    companyId?: number
  ) => {
    setSelectedPractice({
      label: name,
      value: id,
    });

    companyId = companyId || selectedCompany?.value;

    const sites =
      data?.companies
        ?.find((company) => company.id === companyId)
        ?.practices?.find((practice) => practice.id === id)
        ?.sites?.map((site) => {
          return {
            label: site.name,
            value: site.id,
            privateKey: site.privateKey,
          };
        }) || [];

    setSitesData(sites);

    if (sites.length) {
      const firstSite = sites[0];
      handleSiteSelected(
        firstSite.value,
        firstSite.label,
        firstSite.privateKey
      );
    }
  };

  const handleSiteSelected = (
    id: number,
    name: string,
    privateKey?: string
  ) => {
    setSelectedSite({
      label: name,
      value: id,
    });

    // localStorage.setItem("sitePrivateKey", privateKey || "");
  };

  useEffect(() => {
    if (data && data?.companies?.length && enableAutoSelect) {
      handleCompanySelected(data?.companies[0]?.id, data?.companies[0]?.name);
    }
  }, [data]); // eslint-disable-line

  const handleApplyAndSave = () => {
    console.log("selectedCompany", selectedCompany);
    console.log("selectedPractice", selectedPractice);
    console.log("selectedSite", selectedSite);
    setSessionStore({
      ...Object(sessionStore),
      currentCompanyId: selectedCompany?.value,
      currentPracticeId: selectedPractice?.value,
      currentSiteId: selectedSite?.value,
    });
    onApply();
  };
  return (
    <DataCenterPartialsStyled>
      <Box className="heading-container">
        <Typography className="main-heading">Show data results</Typography>
        <Typography className="sub-heading">
          Select the a company, practice and a site to show the results of the
          data table.
        </Typography>
      </Box>
      <Divider className="divider" />
      <Box className="content-container">
        <DataCenterList
          title="Companies"
          subTitle={formatSubtitle(companiesData, "Companies")}
          listItems={companiesData}
          onSelect={(id, name) => {
            handleCompanySelected(id, name);
          }}
          selectedListItem={selectedCompany?.value}
        />
        <DataCenterList
          title="Practices"
          subTitle={formatSubtitle(practicesData, "Practices")}
          listItems={practicesData}
          onSelect={(id, name) => {
            handlePracticeSelected(id, name);
          }}
          selectedListItem={selectedPractice?.value}
        />
        {showSite && (
          <DataCenterList
            title="Sites"
            subTitle={formatSubtitle(sitesData, "Sites")}
            listItems={sitesData}
            onSelect={(id, name, privateKey) => {
              handleSiteSelected(id, name, privateKey);
            }}
            selectedListItem={selectedSite?.value}
          />
        )}
      </Box>
      <Box className="bottom-section">
        <Link to={link}>
          <Button
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={handleApplyAndSave}
          >
            Apply & Show Data
          </Button>
        </Link>
      </Box>
    </DataCenterPartialsStyled>
  );
};

export default DataCenterPartials;
