import { PartnerHook } from "../types";

export const DEFAULT_PARTNER_HOOK = {
  name: "",
  url: "",
  isActive: true,
  sendAppointment: true,
  sendAppointmentStatus: true,
  sendAppointmentType: true,
  sendBlockoff: true,
  sendBreed: true,
  sendClient: true,
  sendClientPhoneNumber: true,
  sendClientEmail: true,
  sendCurrency: true,
  sendEmployee: true,
  sendInvoice: true,
  sendLanguage: true,
  sendLocale: true,
  sendOwnership: true,
  sendPatient: true,
  sendPatientReminder: true,
  sendPractice: true,
  sendResource: true,
  sendSex: true,
  sendSpecies: true,
  sendTimezone: true,
} as PartnerHook;
