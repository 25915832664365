import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuBar from "./MenuBar";
import logo from "../../../../logo.png";
import useBreakpoints from "../../../../helpers/useMedia";
import MobileMenu from "./MobileMenu";
import { IndexStyled } from "./Index.styles";

import defaultTheme from "../../../../theme";
import { GlobalStyles } from "@mui/material";

// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Next In Line
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const MainRawContainer = styled(Box)(() => ({
  backgroundColor: "#080f25",
  border: "none",
  paddingTop: "10vh",
  textAlign: "center",
  minHeight: "100vh",
  width: "100%",
}));

const Layout = ({
  children,
  isRaw,
  isCompact,
}: React.PropsWithChildren & {
  page?: string;
  isRaw?: boolean;
  isCompact?: boolean;
}) => {
  const { isLg } = useBreakpoints();
  const [open, setOpen] = React.useState(false);
  const containerStyling = {
    padding: "28px 32px",
    borderTopLeftRadius: "15px",
  };
  if (isCompact) {
    containerStyling.padding = "0px 0px";
    containerStyling.borderTopLeftRadius = "0";
  }
  return (
    <IndexStyled>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        {!isRaw ? (
          <>
            <Drawer
              PaperProps={{
                sx: {
                  backgroundColor: "transparent",
                  padding: "0",
                  border: "none",
                  width: "100% !important",
                },
              }}
              sx={{ width: "200px", maxHeight: "100vh" }}
              variant={isLg ? "permanent" : "temporary"}
              open={true}
            >
              <Box className="logo-and-search-container">
                <Box className="logo-container">
                  <img src={logo} alt="Logo" className="logo" />
                </Box>
                {/* <Box className="sidebar-search-field-container">
                  <input
                    className="sidebar-search-field"
                    type="text"
                    placeholder="Search"
                  />
                </Box> */}
              </Box>
              <List component="nav" className="menu-bar">
                <MenuBar />
              </List>
            </Drawer>
            <Box component="main" className="main-content">
              <Container className="content-container">
                <Grid container spacing={0}>
                  {/* Chart */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Paper
                      sx={{
                        minHeight: "100%",
                        ...containerStyling,
                        backgroundColor: "#080F25",
                      }}
                      style={{ position: "relative" }}
                      elevation={0}
                    >
                      {children}
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            {!isLg && (
              <MobileMenu open={open} onClose={setOpen.bind(null, false)} />
            )}
          </>
        ) : (
          <MainRawContainer>{children}</MainRawContainer>
        )}
      </ThemeProvider>
    </IndexStyled>
  );
};

export default Layout;
